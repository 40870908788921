import React, { useState } from "react";
import { useLanguage } from "../../../languageContext";
import translations from "../../../translation.json";
import { ApiUrl } from "../../../ApiUrl";
import { toast } from "react-toastify";
import EditAppointmenModal from "./editAppointmenModal";
const TableView = ({
  isDarkMode,
  appointments,
  setDisplayComponent,
  setDeleteStates,
  eventId,
  deleteStates,
  setLoading,
  setAppointments,
  selectedAppointments,
  setSelectedAppointments,
  selectAll,
  setSelectAll,
  myEvents,
  setMyEvents,
  calendarData,
  setIsLoading,
}) => {
  const { language } = useLanguage();
  const token = localStorage.getItem("token");
  const [appointmentData, seAppointmentData] = useState("");

  const confirmationStatusOptions = [
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt1[language],
      value: "CONFIRMED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt2[language],
      value: "WANTCB",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt3[language],
      value: "MSG LEFT",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt4[language],
      value: "NO ANSWER",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt5[language],
      value: "SAVED",
    },
    {
      label:
        translations.createProspective.confirmationSatusOptions.opt6[language],
      value: "CANCEL",
    },
  ];
  const visitStatusOptions = [
    {
      label: translations.createProspective.visitStatusOptions.opt1[language],
      value: translations.createProspective.visitStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt2[language],
      value: translations.createProspective.visitStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt3[language],
      value: translations.createProspective.visitStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt4[language],
      value: translations.createProspective.visitStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt5[language],
      value: translations.createProspective.visitStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt6[language],
      value: translations.createProspective.visitStatusOptions.opt6["en"],
    },
  ];
  const noShowOptions = [
    {
      label: translations.createProspective.noShowStatusOptions.opt1[language],
      value: translations.createProspective.noShowStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt2[language],
      value: translations.createProspective.noShowStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt3[language],
      value: translations.createProspective.noShowStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt4[language],
      value: translations.createProspective.noShowStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt5[language],
      value: translations.createProspective.noShowStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.noShowStatusOptions.opt6[language],
      value: translations.createProspective.noShowStatusOptions.opt6["en"],
    },
  ];

  const handleDelete = async (id, index) => {
    setLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/prospectives/" + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        // toast.success("Dealership deleted successful");
        const newData = appointments.filter((client) => client.id !== id);
        setAppointments(newData);
        // Update delete state for the current item
        const newDeleteStates = [...deleteStates];
        newDeleteStates[index] = false;
        setDeleteStates(newDeleteStates);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleCheckboxChange = (appointmentId) => {
    setSelectedAppointments(
      (prevSelected) =>
        prevSelected.includes(appointmentId)
          ? prevSelected.filter((id) => id !== appointmentId) // Deselect if selected
          : [...prevSelected, appointmentId] // Add if not selected
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      // Deselect all
      setSelectedAppointments([]);
    } else {
      // Select all appointments' IDs
      const allAppointmentIds = appointments.map(
        (appointment) => appointment.id
      );
      setSelectedAppointments(allAppointmentIds);
    }
    setSelectAll(!selectAll); // Toggle the state of selectAll
  };
  function getFormattedDate(appointment_date, appointment_time) {
    // Default time if appointment_time is missing or invalid
    const defaultTime = "09:00:00";
    const timeToUse =
      appointment_time && appointment_time.includes(":")
        ? appointment_time
        : defaultTime;

    // Parse the date and time components
    if (!appointment_date) {
      console.error("Missing appointment date");
      return null;
    }

    const [year, month, day] = appointment_date.split("-").map(Number);
    const [hours, minutes, seconds] = timeToUse.split(":").map(Number);

    // Construct startDate object
    const startDate = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds || 0
    );

    if (isNaN(startDate)) {
      // console.error("Invalid startDate generated");
      return null;
    }

    // console.log("Generated Start Date:", startDate);
    return startDate;
  }

  const handleShowModal = (eventId, event) => {
    // console.log(calendarData);

    const findAppointmentWithRepData = (calendarData, eventId) => {
      for (const data of calendarData) {
        const appointment = data.appointments.find(
          (appointment) => appointment.id == eventId
        );

        if (appointment) {
          seAppointmentData({
            rep_id: appointment.appointment_rep_user_id,
            rep_name: data.rep_name,
            start: getFormattedDate(
              appointment.appointment_date,
              appointment.appointment_time
            ),
            end: getFormattedDate(
              appointment.appointment_end_date,
              appointment.appointment_end_time
            ),
            appointment: appointment,
            ...event,
          });
          var createModal = new window.bootstrap.Modal(
            document.getElementById("editAppointmentModal")
          );
          createModal.show();
        }
      }
      return null; // Return null if no matching appointment is found
    };

    // Usage example
    const data = findAppointmentWithRepData(calendarData, eventId);
  };

  const getTranslatedConfirmationStatus = (status) => {
    const option = confirmationStatusOptions.find(
      (opt) => opt.value === status
    );
    return option ? option.label : status;
  };
  const getTranslatedVisitStatus = (status) => {
    const option = visitStatusOptions.find((opt) => opt.value === status);
    return option ? option.label : status;
  };

  const getTranslatedNoShowStatus = (status) => {
    const option = noShowOptions.find((opt) => opt.value === status);
    return option ? option.label : status;
  };

  return (
    <div
      // className="pt-4"
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      //   justifyContent: "space-between",
      // }}
      // ref={containerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        overflowX: "auto",
        justifyContent: "space-between",
        height: "calc(100vh - 320px)",
      }}
    >
      <EditAppointmenModal
        isDarkMode={isDarkMode}
        appointmentData={appointmentData}
        seAppointmentData={seAppointmentData}
        eventId={eventId}
        setIsLoading={setIsLoading}
        appointments={myEvents}
        setAppointments={setMyEvents}
        appointments2={appointments}
        setAppointments2={setAppointments}
        setDisplayComponent={setDisplayComponent}
      />
      <table className="table table-hover text-center align-middle">
        <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
          <tr>
            <th scope="col" className={`${isDarkMode ? "darkthead" : ""}`}>
              {
                <input
                  type="checkbox"
                  className=""
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              }
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "160px" : "180px",
              }}
            >
              {translations.commonFields.fullName[language]}
            </th>
            {/* <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "160px" : "180px",
              }}
            >
              {translations.commonFields.lastName[language]}
            </th> */}
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "160px" : "180px",
              }}
            >
              {translations.commonFields.phone[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "220px" : "255px",
              }}
            >
              {translations.createProspective.visitStatus[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "220px" : "255px",
              }}
            >
              {translations.createProspective.visitNotes[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "150px" : "150px",
              }}
            >
              {translations.clients.table.callStatus[language]}
            </th>
            {/* <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "100px" : "100px",
              }}
            >
              {translations.createProspective.make[language]}
            </th> */}
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "100px" : "100px",
              }}
            >
              {translations.createProspective.model[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{ minWidth: language === "en" ? "95px" : "90px" }}
            >
              {translations.usersPage.createUser.year[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "160px" : "180px",
              }}
            >
              {translations.clients.table.appointmentDate[language]}
            </th>

            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "160px" : "185px",
              }}
            >
              {translations.clients.table.appointmentTime[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "245px" : "245px",
              }}
            >
              {
                translations.createProspective.appointmentRepresentative[
                  language
                ]
              }
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "195px" : "240px",
              }}
            >
              {translations.createProspective.appointmentComment[language]}
            </th>
            
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "170px" : "195px",
              }}
            >
              {translations.createProspective.confirmationStatus[language]}
            </th>

            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "165px" : "195px",
              }}
            >
              {translations.createProspective.confirmationNotes[language]}
            </th>

            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "170px" : "195px",
              }}
            >
              {translations.clients.table.noShowStatus[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "220px" : "255px",
              }}
            >
              {translations.createProspective.noShowNotes[language]}
            </th>
            <th
              scope="col"
              colSpan={2}
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                position: "-webkit-sticky",
                position: "sticky",
                right: 0,
              }}
            >
              Actions
            </th>
            {/* <th scope="col"></th> */}
          </tr>
        </thead>
        <tbody id="">
          {appointments && appointments.length > 0 ? (
            appointments.map((appointment, index) => {
              // console.log(event.number);
              return (
                <>
                  {
                    <tr className="result-box" key={appointment.id}>
                      <td
                        className="fw-bold"
                        style={
                          {
                            // backgroundColor: "#ced4da66",
                            // borderTop: "1px solid #dee2e6",
                            // borderBottom: "1px solid #dee2e6",
                          }
                        }
                      >
                        {
                          <input
                            type="checkbox"
                            className=""
                            checked={selectedAppointments.includes(
                              appointment.id
                            )}
                            onChange={() =>
                              handleCheckboxChange(appointment.id)
                            }
                          />
                        }
                      </td>
                      <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {appointment?.prospective?.first_name +
                          "" +
                          appointment?.prospective?.last_name}
                      </td>
                      {/* <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {appointment?.prospective?.last_name}
                      </td> */}
                      <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {appointment?.prospective?.phone}
                      </td>
                      <td className="salesrep">
                        {getTranslatedVisitStatus(appointment?.visit_status)}
                      </td>
                      <td className="salesrep">{appointment?.visit_notes}</td>
                      <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {appointment?.prospective?.call_status}
                      </td>
                      {/* <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {appointment?.prospective?.make}
                      </td> */}
                      <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {appointment?.prospective?.model}
                      </td>
                      <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {appointment?.prospective?.year}
                      </td>
                      <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {appointment?.appointment_date}
                      </td>
                      <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {appointment?.appointment_time}
                      </td>
                      <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {appointment?.appointment_rep_user_name}
                      </td>
                      <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {appointment?.appointment_comment}
                      </td>

                      <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {getTranslatedConfirmationStatus(
                          appointment?.confirmation_status
                        )}
                      </td>

                      <td
                        className="salesrep"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleShowModal(appointment.id, appointment)
                        }
                      >
                        {appointment?.confirmation_notes}
                      </td>

                      <td className="salesrep">
                        {getTranslatedNoShowStatus(appointment?.no_show_status)}
                      </td>
                      <td className="salesrep">{appointment?.no_show_notes}</td>
                      <td
                        style={{
                          position: "-webkit-sticky",
                          position: "sticky",
                          right: language == "fr" ? "136px" : "110px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-light edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                          //   data-customer-id="405"
                          //   data-bs-toggle="modal"
                          //   data-bs-target="#AddDealerModal"
                          onClick={() => {
                            // setDisplayComponent("edit-appointment");
                            // //   setEditEventID(event.id);
                            // window.history.pushState(
                            //   {
                            //     id: "gallery",
                            //     randomData: window.Math.random(),
                            //   },
                            //   "title",
                            //   "/event-portal?page=edit-appointment&event_id=" +
                            //     eventId +
                            //     `&client_id=${appointment.id}`
                            // );
                            setDisplayComponent("edit-appointment");
                            //   setEditEventID(event.id);
                            window.history.pushState(
                              {
                                id: "gallery",
                                randomData: window.Math.random(),
                              },
                              "title",
                              "/event-portal?page=edit-appointment&event_id=" +
                                eventId +
                                `&client_id=${appointment.prospective.id}`
                            );
                          }}
                        >
                          <i className="bi bi-eye"></i>{" "}
                          {translations.commonWords.view[language]}
                        </button>
                      </td>

                      <td
                        style={{
                          width: "170px",
                          position: "-webkit-sticky",
                          position: "sticky",
                          right: 0,
                        }}
                      >
                        <div className="btn-group" role="group">
                          {deleteStates[index] ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-success delete-button"
                                data-customer-id="405"
                                onClick={() =>
                                  handleDelete(appointment.id, index)
                                }
                              >
                                {translations.commonFields.confirm[language]}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary cancel-button"
                                onClick={() =>
                                  setDeleteStates((prevStates) => {
                                    const newStates = [...prevStates];
                                    newStates[index] = false;
                                    return newStates;
                                  })
                                }
                              >
                                {translations.commonFields.cancel[language]}
                              </button>
                            </>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-danger before-delete-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                              dataset-button-state="delete"
                              onClick={() =>
                                setDeleteStates((prevStates) => {
                                  const newStates = [...prevStates];
                                  newStates[index] = true;
                                  return newStates;
                                })
                              }
                            >
                              <i className="bi bi-trash"></i>{" "}
                              {translations.commonFields.delete[language]}
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  }
                </>
              );
            })
          ) : (
            <tr
              className="align-middle"
              style={{
                position: "relative",
                // height: "30px",
                // zIndex: 10 + eventList.length - index,
              }}
            >
              <td
                style={{
                  background: `${isDarkMode ? "transparent" : "white"}`,
                  borderStyle: "none",
                }}
                colSpan={16}
                className="text-center py-5"
              >
                <h4>{translations.EventsPortal.noDataMessage[language]}</h4>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* <div ref={bottomMarkerRef} style={{ height: "1px" }} /> */}
    </div>
  );
};

export default TableView;
