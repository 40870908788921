import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../languageContext";
import translations from "../../../translation.json";
import { ApiUrl } from "../../../ApiUrl";
import { toast } from "react-toastify";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import Select from "react-select";
import { useFormikContext } from "formik";
import * as Yup from "yup";

const AddProspectFormModal = ({
  eventId,
  isDarkMode,
  setDisplayComponent,
  setIsLoading,
  users,
  listId,
  setSelectedProspect,
  selectedProspect,
  setClients,
  clients,
  last,
  lastFormValues,
  setLastValues,
}) => {
  const { language } = useLanguage();
  const token = localStorage.getItem("token");
  const [repOptions, setRepOptions] = useState([]);
  const [listIds, setListIds] = useState([]);
  var button = document.getElementById("addProspectModalClose");

  const initialData = {
    activix_id: "",
    dialer_id: "",
    list_id: listId?.list_id,
    list_name: listId?.list_name,
    private_event_id: eventId,
    confirmation_agent_name: "",
    prospect_type: "",
    first_name: "",
    last_name: "",
    phone: "",
    make: "",
    model: "",
    year: "",
    appointment_date: "",
    appointment_entry_date: "",
    appointment_rep_user_name: "",
    appointment_agent_name: "",
    appointment_time: "",
    appointment_comment: "",
    // appointment_status:
    //   editMode && prospectData
    //     ? prospectData.appointments[0]?.appointment_status
    //     : "",
    appointment_status_notes: "",
    confirmation_status: "",
    confirmation_notes: "",
    prospect_source: "",
    greetings: "",
    mobile_phone: "",
    office_phone: "",
    address: "",
    city: "",
    province: "",
    postal_code: "",
    email: "",
    language: "",
    purchase_date: "",
    last_service_date: "",
    km: "",
    vin: "",
    call_status: "",
    confirmation_status_entry_date: "",
    visit_status: "",
    visit_status_entry_date: "",
    visit_notes: "",
    visit_status_user_name: "",
    no_show_status: "",
    no_show_status_entry_date: "",
    no_show_notes: "",
    no_show_user_name: "",
    appointment: "",
    og_rep: "",
    // appointment_id: editMode && prospectData ? prospectData.appointments[0]?.id : "",
  };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(
      translations.appointments.validations.firstName[language]
    ),
    phone: Yup.string().required(
      translations.quickCreationModal.validationsText.phoneNumber[language]
    ),
    prospect_source: Yup.string().required(
      translations.appointments.validations.prospectSource[language]
    ),
  });

  const prospectiveSourceOptions = Object.keys(
    translations.createProspective.prospectiveSourceOptions
  ).map((key) => ({
    label: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
    value: {
      en: translations.createProspective.prospectiveSourceOptions[key]["en"],
      fr: translations.createProspective.prospectiveSourceOptions[key]["fr"],
    },
  }));

  const appointmentOption = [
    { label: translations.dropdown.yes[language], value: "1" },
    { label: translations.dropdown.no[language], value: "0" },
  ];

  useEffect(() => {
    const getListIds = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(ApiUrl + "/api/user/list-ids", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          const lists =
            data?.list_ids?.lists &&
            data?.list_ids.lists?.length > 0 &&
            data?.list_ids.lists?.map((list) => {
              return { label: list.list_name, value: list.list_id };
            });

          setListIds(lists);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error("Something went wrong2");
      } finally {
      }
    };
    const getExternalUsers = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(ApiUrl + "/api/user/external-user/" + eventId, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          const repData = data?.externalUsers?.length
            ? data?.externalUsers.map((list) => ({
                label: list.appointment_rep_user_name,
                value: list.appointment_rep_user_name,
              }))
            : [];
          setRepOptions(repData);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error("Something went wrong");
      } finally {
      }
    };

    getListIds();
    if (eventId) {
      getExternalUsers();
    }
  }, [eventId]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const url = `${ApiUrl}/api/user/prospectives`;
      const bodyData = values;
      const method = "POST";
      const res = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });
      // console.log("res", res)
      const data = await res.json();
      // console.log("data", data)
      if (data.status === "success") {
        toast.success(data.message);
        // console.log(lastFormValues);
        setClients([
          ...clients,
          {
            label:
              data.prospective.first_name + " " + data.prospective.last_name,
            value: data.prospective.id,
          },
        ]);
        setSelectedProspect({
          label: data.prospective.first_name + " " + data.prospective.last_name,
          value: data.prospective.id,
        });
        button.click();

        // navigate("/event-portal");
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };
  // console.log(listId);

  return (
    <div id="addProspectModal" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            // id="creationsuccess"
            enableReinitialize={true}
          >
            {({ errors, values, setValues, resetForm, isSubmitting }) => (
              <Form>
                <div
                  class="modal-header"
                  style={{
                    borderBottom: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <h3 style={{ color: "#000000" }}>
                    {translations.createProspective.title[language]}
                  </h3>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    onClick={() => button.click()}
                    style={{
                      filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                      color: isDarkMode
                        ? "#ffffff"
                        : "rgb(255, 255, 255) !important",
                    }}
                    id="closeModalBtn"
                  ></button>
                </div>
                <div
                  className="modal-body"
                  style={{ height: "350px", overflowY: "scroll" }}
                >
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.firstName[language]}
                        </label>
                        <Field
                          type="text"
                          name="first_name"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />

                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.lastName[language]}
                        </label>
                        <Field
                          type="text"
                          name="last_name"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.phone[language]}
                        </label>
                        <Field
                          type="text"
                          name="phone"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />

                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {
                            translations.createProspective.prospectiveSource[
                              language
                            ]
                          }
                        </label>
                        {/* <Field type="text" name="digital" className="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                        <Field
                          name="prospect_source"
                          render={({ field, form }) => {
                            const getCurrentOption = () => {
                              // Try to find a match in both English and French values
                              const matchedOption =
                                prospectiveSourceOptions.find(
                                  (option) =>
                                    option.value.en?.toLowerCase() ===
                                      field.value?.toLowerCase() ||
                                    option.value.fr?.toLowerCase() ===
                                      field.value?.toLowerCase()
                                );

                              // If a match is found, return the option with the current language label
                              if (matchedOption) {
                                return {
                                  value: matchedOption.value.en, // Always store English value
                                  label: matchedOption.label[language],
                                };
                              }

                              // If no match found, return a default option
                              return {
                                value: field.value || "",
                                label:
                                  field.value ||
                                  translations["dropdown"].select[language],
                              };
                            };
                            return (
                              <Select
                                className="selectpicker"
                                // isClearable
                                options={prospectiveSourceOptions.map(
                                  (option) => ({
                                    value: option.value.en, // Always use English value for storage
                                    label: option.label[language],
                                  })
                                )}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={getCurrentOption()}
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"85px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            );
                          }}
                        />
                        <ErrorMessage
                          name="prospect_source"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.createProspective.make[language]}
                        </label>
                        <Field
                          type="text"
                          name="make"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />

                        <ErrorMessage
                          name="make"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.createProspective.model[language]}
                        </label>
                        <Field
                          type="text"
                          name="model"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="model"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.usersPage.createUser.year[language]}
                        </label>
                        <Field
                          type="number"
                          name="year"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />

                        <ErrorMessage
                          name="year"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          KM
                        </label>
                        <Field
                          type="text"
                          name="km"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="km"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.clients.table.callStatus[language]}
                        </label>
                        <Field
                          type="text"
                          name="call_status"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="call_status"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.address[language]}
                        </label>
                        <Field
                          type="text"
                          name="address"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.city[language]}
                        </label>
                        <Field
                          type="text"
                          name="city"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.province[language]}
                        </label>
                        <Field
                          type="text"
                          name="province"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="province"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>{" "}
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          Activix ID
                        </label>
                        <Field
                          type="text"
                          name="activix_id"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />

                        <ErrorMessage
                          name="activix_id"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.clients.table.prospectiveType[language]}
                        </label>
                        <Field
                          type="text"
                          name="prospect_type"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />

                        <ErrorMessage
                          name="prospect_type"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label className="form-label">
                          {translations.createProspective.listIds[language]}
                        </label>
                        <Field
                          name="list_id"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              options={listIds}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isDisabled={true}
                              isSearchable
                              value={
                                (listIds &&
                                  listIds.length > 0 &&
                                  listIds.find(
                                    (option) => option.value === field.value
                                  )) || {
                                  value: "",
                                  label: "",
                                }
                              }
                              onChange={(selectedOption) =>
                                setValues({
                                  ...values,
                                  list_id: selectedOption.value,
                                  list_name: selectedOption.label,
                                })
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "#ced4da66"
                                    : "#ced4da66",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                  display: "none",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        />
                        <ErrorMessage
                          name="list_id"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {
                            translations.forms.formFields.conquestSection
                              .postalCode[language]
                          }
                        </label>
                        <Field
                          type="text"
                          name="postal_code"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="postal_code"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {
                            translations.createProspective.officeNumber[
                              language
                            ]
                          }
                        </label>
                        <Field
                          type="text"
                          name="office_phone"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="office_phone"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label className="form-label">
                          {translations.appointments.appointment[language]}
                        </label>
                        <Field
                          name="appointment"
                          render={({ field, form }) => (
                            <Select
                              className="selectpicker"
                              aria-labelledby="dealership-name-quick"
                              // isClearable
                              options={appointmentOption}
                              placeholder={
                                translations["dropdown"].select[language]
                              }
                              isSearchable
                              value={
                                (appointmentOption &&
                                  appointmentOption.length > 0 &&
                                  appointmentOption.find(
                                    (option) => option.value === field.value
                                  )) || {
                                  value: "",
                                  label:
                                    translations["dropdown"].select[language],
                                }
                              }
                              onChange={(selectedOption) =>
                                form.setFieldValue(
                                  field.name,
                                  selectedOption.value
                                )
                              }
                              maxMenuHeight={"120px"}
                              styles={{
                                input: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "white",
                                  border: state.isFocused
                                    ? "1px solid #80bdff"
                                    : isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6", // Adjust the border color for focus
                                  boxShadow: state.isFocused
                                    ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                    : "none", // Bootstrap's box shadow for focus
                                  borderRadius: ".45rem",
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                }),
                                indicatorSeparator: (provided) => ({
                                  ...provided,
                                  display: "none", // Hide the indicator separator
                                }),
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                  color: isDarkMode ? "#ffffff" : "#868686",
                                  display: "block",
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  margin: "0", // Remove default margin
                                }),
                                placeholder: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                  // Additional styles for placeholder
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  color: isDarkMode ? "#ffffff" : "black",
                                  backgroundColor: state.isHovered
                                    ? "#80bdff"
                                    : isDarkMode
                                    ? "#1f2023"
                                    : "white",
                                  ":hover": {
                                    backgroundColor: isDarkMode
                                      ? "#34DBB4"
                                      : "#34DBB4",
                                  },
                                }),
                              }}
                            />
                          )}
                        />
                        <ErrorMessage
                          name="appointment"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.mobileNumber[language]}
                        </label>
                        <Field
                          type="text"
                          name="mobile_phone"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />

                        <ErrorMessage
                          name="mobile_phone"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.language[language]}
                        </label>
                        <Field
                          type="text"
                          name="language"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="language"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.createProspective.VIN[language]}
                        </label>
                        <Field
                          type="text"
                          name="vin"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="vin"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {
                            translations.createProspective.purchaseDate[
                              language
                            ]
                          }
                        </label>
                        <Field
                          type="date"
                          name="purchase_date"
                          className={`form-control ${
                            isDarkMode ? "custom-date" : ""
                          }`}
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              purchase_date: e.target.value,
                            });
                          }}
                        />

                        <ErrorMessage
                          name="purchase_date"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {
                            translations.createProspective.lastServiceDate[
                              language
                            ]
                          }
                        </label>
                        <Field
                          type="date"
                          name="last_service_date"
                          className={`form-control ${
                            isDarkMode ? "custom-date" : ""
                          }`}
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              last_service_date: e.target.value,
                            });
                          }}
                        />

                        <ErrorMessage
                          name="last_service_date"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.email[language]}
                        </label>
                        <Field
                          type="email"
                          name="email"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>{" "}
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.createProspective.greetings[language]}
                        </label>
                        <Field
                          type="text"
                          name="greetings"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="greetings"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-6">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          OG Rep
                        </label>
                        <Field
                          type="text"
                          name="og_rep"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="og_rep"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div
                  class="modal-footer"
                  id="modalfooter"
                  style={{
                    borderTop: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <div className="row">
                    <div className="col-sm" style={{ padding: 7 }}>
                      <button
                        type="submit"
                        className="client-btn btn-success mx-2"
                        id="add-customer-button"
                        disabled={isSubmitting}
                      >
                        <i className="bi bi-save"></i>{" "}
                        {translations["commonFields"].submitBtn[language]}
                      </button>
                      <button
                        id="addProspectModalClose"
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                        // data-bs-toggle={quickAdd ? "modal" : ""}
                        // data-bs-target={quickAdd ? "#modalQuickAdd" : ""}
                        // onClick={() => {
                        //   resetForm();
                        //   setValues({
                        //     name: "",
                        //     country: "",
                        //     state: "",
                        //     city: "",
                        //     address: "",
                        //     postal_code: "",
                        //     timezone: "",
                        //     phone_number: "",
                        //     primary_contact: "",
                        //     group_id: "",
                        //     representative_id: "",
                        //     brand_id: "",
                        //     logo: "",
                        //     crm_link: "",
                        //   });
                        //   setSelected("");
                        // }}
                      >
                        {translations["commonFields"].closeBtn[language]}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddProspectFormModal;
