import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { ApiUrl } from "../../../ApiUrl";
import { toast } from "react-toastify";
import { parseISO, format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { useLanguage } from "../../../languageContext";
import translations from "../../../translation.json";
import EditAppointmenModal from "./editAppointmenModal";
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

function CalendarView2({
  isDarkMode,
  calendarData,
  setCalendarData,
  myEvents,
  setMyEvents,
  eventId,
  setIsLoading,
  setDisplayComponent,
  currentDate,
  setCurrentDate,
  timeRange,
}) {
  const { language } = useLanguage();

  // const events = [
  //   {
  //     id: 0,
  //     title: "Board meeting",
  //     start: new Date(2024, 10, 6, 0, 0, 0), // October is month 9 in JS
  //     end: new Date(2024, 10, 6, 0, 30, 0),
  //     resourceId: 1,
  //   },
  //   {
  //     id: 1,
  //     title: "MS training",
  //     // allDay: true,
  //     start: new Date(2024, 10, 6, 9, 0, 0),
  //     end: new Date(2024, 10, 6, 13, 30, 0),
  //     resourceId: 2,
  //   },
  //   {
  //     id: 2,
  //     title: "Team lead meeting",
  //     start: new Date(2024, 10, 5, 8, 30, 0),
  //     end: new Date(2024, 10, 5, 12, 30, 0),
  //     resourceId: 3,
  //   },
  //   {
  //     id: 11,
  //     title: "Birthday Party",
  //     start: new Date(2024, 10, 5, 21, 0, 0),
  //     end: new Date(2024, 10, 5, 23, 30, 0),
  //     resourceId: 4,
  //   },
  // ];

  // const resourceMap = [
  //   { resourceId: 1, resourceTitle: "Board room" },
  //   { resourceId: 2, resourceTitle: "Training room" },
  //   { resourceId: 3, resourceTitle: "Meeting room 1" },
  //   { resourceId: 4, resourceTitle: "Meeting room 2" },
  // ];

  const resourceMap =
    calendarData && calendarData.length > 0
      ? calendarData.map((item) => {
          return { resourceId: item?.rep_name, resourceTitle: item?.rep_name };
        })
      : [];

  const [appointmentData, seAppointmentData] = useState("");

  const [currentView, setCurrentView] = useState(Views.DAY);

  const token = localStorage.getItem("token");
  const [calendarKey, setCalendarKey] = useState(0);
  const [hoveredEventId, setHoveredEventId] = useState(null);

  const handleEventHover = (eventId, event) => {
    // console.log(calendarData);

    const findAppointmentWithRepData = (calendarData, eventId) => {
      for (const data of calendarData) {
        const appointment = data.appointments.find(
          (appointment) => appointment.id == eventId
        );

        if (appointment) {
          seAppointmentData({
            rep_id: appointment.appointment_rep_user_id,
            rep_name: data.rep_name,
            appointment: appointment,
            ...event,
          });
          var createModal = new window.bootstrap.Modal(
            document.getElementById("editAppointmentModal")
          );
          createModal.show();
        }
      }
      return null; // Return null if no matching appointment is found
    };

    // Usage example
    const data = findAppointmentWithRepData(calendarData, eventId);
    // if (appointmentData) {
    //   var createModal = new window.bootstrap.Modal(
    //     document.getElementById("editAppointmentModal")
    //   );
    //   createModal.show();
    // }
  };

  const { defaultDate, views, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      views: ["day", "month"],
      // scrollToTime: new Date(1972, 0, 1, 8),
    }),
    []
  );

  const moveEvent = useCallback(
    ({
      event,
      start,
      end,
      isAllDay: droppedOnAllDaySlot = false,
      resourceId,
    }) => {
      // const { allDay } = event;
      // if (!allDay && droppedOnAllDaySlot) {
      //   event.allDay = true;
      // }

      // console.log(event, start, end,resourceId);

      // setMyEvents((prevEvents) =>
      //   prevEvents.map((prevEvent) =>
      //     prevEvent?.id === event?.id
      //       ? { ...event, start, end, resourceId }
      //       : prevEvent
      //   )
      // );

      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, resourceId }];
      });
      const appointment_date = format(start, "yyyy-MM-dd"); // "2024-11-06"
      const appointment_time = format(start, "HH:mm");

      dragAppointment(
        event?.id,
        resourceId,
        appointment_date,
        appointment_time
      );
    },
    [setMyEvents]
  );

  useEffect(() => {
    // Update calendarKey to force re-render when `myEvents` updates
    setCalendarKey((prevKey) => prevKey + 1);
    console.log("here");
  }, [myEvents, currentDate]);

  const handleViewChange = (view) => {
    setCurrentView(view); // Track current view to prevent switching
  };

  async function dragAppointment(
    id,
    appointment_rep_user_id,
    appointment_date,
    appointment_time
  ) {
    try {
      setIsLoading(true);
      const res = await fetch(ApiUrl + "/api/user/drag-appointment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: id,
          appointment_rep_user_name: appointment_rep_user_id,
          appointment_date: appointment_date,
          appointment_time: appointment_time,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        // const newData = data.private_events.data;
        // setDashboardData(data);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  }
  // console.log(myEvents);

  const eventPropGetter = (event, start, end, eventIndex) => {
    // const leftPosition = eventIndex * 250; // Each event is offset by 300px
    // const styleSheet = document.styleSheets[0];

    // if (myEvents && myEvents.length > 0) {
    //   const styleSheet = document.styleSheets[0];
    //   myEvents.forEach((event, eventIndex) => {
    //     const leftPosition = eventIndex * 300;

    //     // Inject dynamic CSS rule for each event based on eventIndex
    //     const customEventStyle = `
    //       .custom-rbc-event${eventIndex} {
    //         left: ${leftPosition}px !important;
    //       }
    //     `;

    //     // Insert the rule into the stylesheet
    //     styleSheet.insertRule(customEventStyle, styleSheet.cssRules.length);
    //   });
    // }

    return {
      style: {
        // width: `${widthPercentage}%`, // Each event takes a percentage of the available width
        backgroundColor: "transparent",
        border: "none",
        display: "flex",
        height: "100%",
        justifyContent: "space-between",
        maxWidth: currentView !== "month" ? "" : "", // You can adjust the maxWidth or remove it as per your needs
        minWidth: currentView !== "month" ? "300px" : "", // Same for minWidth
        // left: `${leftPosition}px !important`, // Dynamically set the left position based on event index
      },
      className: `custom-rbc-event${eventIndex}`,
    };
  };

  const updateStyles = useCallback(() => {
    // Check if style element already exists
    let styleElement = document.getElementById("dynamic-styles");

    // If the style element doesn't exist, create it
    if (!styleElement) {
      styleElement = document.createElement("style");
      styleElement.id = "dynamic-styles";
      document.head.appendChild(styleElement);
    }

    const ruleText = `.rbc-header { min-width: 300px !important; }`;

    if (currentView === "month") {
      // If in "month" view, remove the rule if it exists
      if (styleElement.textContent.includes(ruleText)) {
        styleElement.textContent = styleElement.textContent.replace(
          ruleText,
          ""
        );
      }
    } else {
      // If not in "month" view, add the rule if it doesn't already exist
      if (!styleElement.textContent.includes(ruleText)) {
        styleElement.textContent += ruleText;
      }
    }
  }, [currentView]);

  useEffect(() => {
    updateStyles();
  }, [updateStyles]);

  const handleNavigate = (date) => {
    setCurrentDate(date); // Set the new date when navigating
  };

  // console.log(new Date(2024, 1, 1, parseInt(timeRange?.start_time, 10) || 0, 0, 0));

  return (
    <div>
      <EditAppointmenModal
        isDarkMode={isDarkMode}
        appointmentData={appointmentData}
        seAppointmentData={seAppointmentData}
        eventId={eventId}
        setIsLoading={setIsLoading}
        appointments={myEvents}
        setAppointments={setMyEvents}
        setDisplayComponent={setDisplayComponent}
      />

      <div
        className=""
        style={{
          height: "calc(100vh - 270px)",
          overflow: "auto",
          width: "100%",
        }}
      >
        {/* {myEvents && myEvents.length > 0 ? ( */}
        <DnDCalendar
          key={calendarKey}
          defaultDate={currentDate}
          events={myEvents}
          localizer={localizer}
          resourceIdAccessor="resourceId"
          resources={resourceMap}
          resourceTitleAccessor="resourceTitle"
          // dayLayoutAlgorithm={currentView === "day" ? "no-overlap" : "overlap"}
          dayLayoutAlgorithm="no-overlap"
          // showMultiDayTimes
          step={15}
          views={views}
          view={currentView} // Maintain the current view state
          onView={handleViewChange} // Update view state on view change
          onEventDrop={moveEvent}
          // onNavigate={handleNavigate} // Update date on navigate
          onNavigate={(date) => setCurrentDate(date)}
          // draggableAccessor={(event) =>
          //   currentView === "month" ? false : true
          // }
          onSelectEvent={(event) => {
            handleEventHover(event.id, event);
          }}
          draggableAccessor={(event) => currentView !== "month"}
          min={
            timeRange?.start_time
              ? new Date(
                  2024,
                  1,
                  1,
                  parseInt(timeRange?.start_time, 10) || 0,
                  0,
                  0
                )
              : new Date(2024, 1, 1, 6, 0, 0)
          } // Start time: 6 AM
          max={
            timeRange?.end_time
              ? new Date(
                  2024,
                  1,
                  1,
                  parseInt(timeRange?.end_time, 10) || 0,
                  0,
                  0
                )
              : new Date(2024, 1, 1, 22, 30, 0)
          } // End time: 10 PM
          eventPropGetter={(event, start, end) => {
            const eventIndex = myEvents.indexOf(event);
            return eventPropGetter(event, start, end, eventIndex);
          }} // Pass eventIndex here
          components={
            currentView === "month"
              ? {}
              : {
                  event: (props) => (
                    <CustomEvent
                      {...props}
                      hoveredEventId={hoveredEventId} // Pass hover state to child
                      onClick={handleEventHover} // Pass hover handler to child
                    />
                  ),
                }
          }
        />
        {/* ) : (
        <h4>{translations.EventsPortal.noDataMessage[language]}</h4>
      )} */}
      </div>
    </div>
  );
}

function CustomEvent({ event, hoveredEventId, onClick }) {
  const [isDragging, setIsDragging] = useState(false);
  const timerRef = useRef(null);

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  //   onClick(event.id); // Trigger the hover state change in parent
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  //   onClick(null); // Reset hover state when mouse leaves
  // };

  const handleMouseDown = (e) => {
    // Start tracking a potential drag
    setIsDragging(false);

    timerRef.current = setTimeout(() => {
      setIsDragging(true); // If the user holds for some time, enable drag
    }, 200); // Adjust the duration for hold detection
  };

  const handleMouseUp = (e) => {
    clearTimeout(timerRef.current);

    // If it's a short press, open the modal
    if (!isDragging) {
      onClick(event.id, event);
    }

    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    clearTimeout(timerRef.current);
    setIsDragging(false); // Reset dragging state if the user leaves the element
  };

  const handleLocalDate = (localDate) => {
    const dateObject = new Date(localDate);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Convert to the specified timezone
    const zonedTime = toZonedTime(dateObject, timeZone);

    // Format for React Big Calendar or any other usage
    const formattedDate = format(zonedTime, "yyyy-MM-dd", {
      timeZone,
    });

    return formattedDate;
  };

  function setDivColor(type) {
    if (type === "bgColor") {
      if (
        event.data?.appointment?.confirmation_status === "" ||
        event.data?.appointment?.confirmation_status === null
      ) {
        return "#445675";
      }

      if (
        event.data?.appointment?.visit_status !== null && event.data?.appointment?.visit_status !== "" &&
        event.data?.appointment?.visit_status === "Absent"
      ) {
        return "#b42b39";
      }

      if (
        event.data?.appointment?.visit_status !== null && event.data?.appointment?.visit_status !== "" &&
        event.data?.appointment?.visit_status !== "Absent"
      ) {
        return "#32DBB4";
      }

      if (
        event.data?.appointment?.confirmation_status !== null && event.data?.appointment?.confirmation_status !== "" &&
        event.data?.appointment?.confirmation_status === "CONFIRMED"
      ) {
        return "#327edb";
      }
      if (
        event.data?.appointment?.visit_status === "" ||
        event.data?.appointment?.visit_status === null
      ) {
        return "#445675";
      }
    } else {
      if (
        event.data?.appointment?.confirmation_status === "" ||
        event.data?.appointment?.confirmation_status === null
      ) {
        return "1px solid #394862";
      }
      if (
        event.data?.appointment?.visit_status !== null && event.data?.appointment?.visit_status !== "" &&
        event.data?.appointment?.visit_status === "Absent"
      ) {
        return "1px solid #b42b39";
      }
      if (
        event.data?.appointment?.visit_status !== null && event.data?.appointment?.visit_status !== "" &&
        event.data?.appointment?.visit_status !== "Absent"
      ) {
        return "1px solid #28B59E";
      }
      if (
        event.data?.appointment?.confirmation_status !== null && event.data?.appointment?.confirmation_status !== "" &&
        event.data?.appointment?.confirmation_status === "CONFIRMED"
      ) {
        return "1px solid #2847b5";
      }
      if (
        event.data?.appointment?.visit_status === "" ||
        event.data?.appointment?.visit_status === null
      ) {
        return "1px solid #394862";
      }
    }
  }

  return (
    <div
      className="custom-event d-flex justify-content-between w-100"
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      // onMouseUp={handleMouseUp}
      onClick={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      style={{
        // backgroundColor:
        //   event.data?.appointment?.confirmation_status === "CONFIRMED"
        //     ? "#327edb"
        //     : event?.data?.appointment?.visit_status !== "Absent" ? "" : "#445675",
        backgroundColor: setDivColor("bgColor"),

        borderRadius: "0.375rem",
        border: setDivColor("borderColor"),
        // maxWidth: "300px",
        height: "100%",
        // border:
        //   event.data?.appointment?.confirmation_status === "CONFIRMED"
        //     ? "1px solid #2847b5"
        //     : "1px solid #394862",
        // color:
        //   event.data?.appointment?.confirmation_status === "CONFIRMED"
        //     ? "#000000 !important"
        //     : "#ffffff !important",
      }}
    >
      <div
        className="appointment_bubble d-flex flex-wrap justify-content-between align-items-start w-100"
        style={{ gap: "0.5rem" }} // Adds spacing between wrapped items
      >
        <div
          className="py-3 px-2 click-for-apt-container"
          style={{ flex: "1 1 auto", minWidth: "200px" }} // Responsive flex behavior
        >
          <div className="mb-1 fs-6 ">
            <div className="appointment_container_header d-flex justify-content-between align-items-center mb-2 flex-wrap pt-2">
              {/* First Name and Last Name */}
              <h4
                className={`text-break ${"confirmed-color"}`}
                style={{
                  margin: 0,
                  maxWidth: "calc(100% - 20px)", // Ensure it doesn't overflow by allowing some space
                  wordWrap: "break-word", // Allow long words to break to next line
                  overflowWrap: "break-word", // Ensure text wrapping works
                }}
              >
                {event?.title}
              </h4>
              {event.data?.appointment?.visit_status !== "" &&
                event.data?.appointment?.visit_status !== null &&
                event.data?.appointment?.visit_status !== "Absent" &&
                event.data?.appointment?.visit_status !== "Present" && (
                  <div
                    className={`appointment_bubble_status visitStatus-tooltip`}
                    // data-bs-toggle="tooltip"
                    // data-bs-placement="top"
                    // data-bs-title="Sold (New)"
                  >
                    <i
                      className={`${
                        event.data?.appointment?.visit_status === "Sold (New)"
                          ? "bi bi-car-front-fill"
                          : event.data?.appointment?.visit_status ===
                            "Sold (Used)"
                          ? "bi bi-car-front"
                          : event.data?.appointment?.visit_status ===
                            "In follow-up"
                          ? "bi bi-lightning-fill"
                          : "bi bi-ban"
                      }  ${
                        (event.data?.appointment?.confirmation_status === "" ||
                          event.data?.appointment?.confirmation_status ===
                            null) &&
                        (event.data?.appointment?.visit_status === "" ||
                          event.data?.appointment?.visit_status === null)
                          ? "confirmed-color"
                          : "default-color"
                      }`}
                    ></i>
                    <span className="visitStatus-tooltiptext">
                      {event.data?.appointment?.visit_status}
                    </span>
                  </div>
                )}
            </div>
            <div className="d-flex align-items-center gap-2 flex-wrap mb-2 fs-6">
              {/* Appointment Date and Time */}
              <div className="d-flex align-items-center gap-1">
                <i className={`bi bi-calendar ${"confirmed-color"}`}></i>
                <p
                  className={`m-0 text-truncate ${"confirmed-color"}`}
                  style={{
                    maxWidth: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {/* {handleLocalDate(event?.data.appointment?.appointment_date)} */}
                  {event?.data.appointment?.appointment_date}
                </p>
              </div>
              <div className="d-flex align-items-center gap-1">
                <i className={`bi bi-clock-fill ${"confirmed-color"}`}></i>
                <p
                  className={`m-0 text-truncate ${"confirmed-color"}`}
                  style={{
                    maxWidth: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {format(event?.start, "hh:mm aa")}
                </p>
              </div>
            </div>
            {/* Appointment Representative */}
            <div className="d-flex align-items-center">
              <i className={`bi bi-suitcase-lg-fill ${"confirmed-color"}`}></i>
              <span className={`ms-1 text-truncate ${"confirmed-color"}`}>
                {event.data?.appointment?.appointment_rep_user_name}{" "}
              </span>
            </div>
            <div className="py-3">
              <p className={`text-truncate ${"confirmed-color"}`}>
                {event.data?.appointment?.visit_notes}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <p onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {event.title}
      </p> */}
      <div
        className="drag-handle p-2"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          width: "10%",
          /* Darker background with opacity */
          cursor: "grab",
          /* Change mouse to hand icon */
          borderRadius: "0rem 0.375rem 0.375rem 0rem",
        }}
      >
        &nbsp;
      </div>
    </div>
  );
}

export default CalendarView2;
